import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Minor from '../views/minor.vue'
import Foodybag from '../views/minor-content/foodybag.vue'
import Graphichero from '../views/minor-content/graphic-hero.vue'
import Fotografie from '../views/minor-content/fotografie.vue'
import Jamesbond from '../views/minor-content/james-bond.vue'
import Archetypes from '../views/minor-content/archetypes.vue'
import Surrealistisch from '../views/minor-content/surrealistische-wereld.vue'
import Portretbewerking from '../views/minor-content/portretbewerking.vue'
import Fotografie2 from '../views/minor-content/fotografie-2.vue'
import Bierpull from '../views/minor-content/bierpull.vue'
import Salvemundi from '../views/minor-content/salvemundi.vue'
import Photoshopportret from '../views/minor-content/photoshop-portret.vue'
import Huisstijl from '../views/minor-content/huisstijl.vue'
import TransparentIT from '../views/minor-content/transparent-it.vue'
import Typografie from '../views/minor-content/typografie.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/minor',
    name: 'Minor',
    component: Minor
  },
  {
    path: '/minor-content/foodybag',
    name: 'Foodybag',
    component: Foodybag
  },
  {
    path: '/minor-content/graphic-hero',
    name: 'Graphichero',
    component: Graphichero
  },
  {
    path: '/minor-content/fotografie',
    name: 'Fotografie',
    component: Fotografie
  },
  {
    path: '/minor-content/james-bond',
    name: 'Jamesbond',
    component: Jamesbond
  },
  {
    path: '/minor-content/archetypes',
    name: 'Archetypes',
    component: Archetypes
  },
  {
    path: '/minor-content/surrealistische-wereld',
    name: 'Surrealistisch',
    component: Surrealistisch
  },
  {
    path: '/minor-content/portretbewerking',
    name: 'Portretbewerking',
    component: Portretbewerking
  },
  {
    path: '/minor-content/fotografie-2',
    name: 'Fotografie2',
    component: Fotografie2
  },
  {
    path: '/minor-content/bierpull',
    name: 'Bierpull',
    component: Bierpull
  },
  {
    path: '/minor-content/salvemundi',
    name: 'Salvemundi',
    component: Salvemundi
  },
  {
    path: '/minor-content/photoshop-portret',
    name: 'Photoshopportret',
    component: Photoshopportret
  },
  {
    path: '/minor-content/huisstijl',
    name: 'Huisstijl',
    component: Huisstijl
  },
  {
    path: '/minor-content/transparent-it',
    name: 'TransparentIT',
    component: TransparentIT
  },
  {
    path: '/minor-content/typografie',
    name: 'Typografie',
    component: Typografie
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
