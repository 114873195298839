<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p"></p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/foodybag">
      <p class="button-p">Tas van verschil</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/hansjevanhalem.png">
        <img class="img-page" src="/img/minor/content-img/hansjevanhalem.png"
      /></a>
      <p class="title-page">GRAPHIC HERO FLYER</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Ik heb voor mijn Graphic Hero flyer gekozen voor Hansje van Halem.
        Hansje van halem (geboren in 1978), is een Nederlandse grafisch
        ontwerper en letterontwerper (afbeelding 1, onderaan de pagina). Ze
        heeft grafische en typografische vormgeving gestudeerd aan de
        Koninklijke Academie van Beeldende Kunsten in Den Haag en ze heeft op de
        Rietveld Academie haar diploma Graphic Design behaald. De ontwerpen van
        Van Halem zijn in veel tentoonstellingen en musea terug te zien,
        waaronder in de collectie van het Stedelijk Museum Amsterdam (NL) en in
        de Bijzondere Collecties, UvA, Amsterdam (NL).
        <br /><br />
        Hansje van Halem is sinds 2017 de hoofdontwerper van het muziekfestival
        Lowlands, waar haar werk voor een groot publiek te bewonderen is
        (afbeelding 2, onderaan de pagina). In 2018 heeft ze voor haar
        Lowlands-ontwerpen een Dutch Design Award ontvangen.

        <br /><br /><br />
        <b> Waarom heb ik voor Hansje van Halem gekozen?</b> <br />
        Het eerste wat me opviel van haar werk is dat het een soort van ‘doodle’
        stijl heeft. Ze doet de gehele ruimte opvullen met vormen en lijnen.
        ‘Doodlen’ heb ik vroeger zelf veel gedaan in de klas tijdens de uitleg
        van een docent. “Als je toch alleen maar hoefde te luisteren, dan kon ik
        ondertussen net zo goed lekker tekenen.” Verder sprak het werk van
        Hansje van Halem mij erg aan door het gebruik van zwart-wit. Dit komt
        ook terug in mijn eigen stijl. Als laatste vind ik dat haar werk iets
        weg heeft van optische illusies. Je hebt in één oogopslag niet meteen
        het hele kunstwerk gezien en als je dichterbij gaat kijken wordt het
        steeds duidelijker hoe de vormen in elkaar overlopen en hoe de structuur
        in elkaar zit.
      </div>
      <div class="text" id="section2">
        Als eerbetoon aan Hansje van Halem heb ik flyer gemaakt op basis van
        haar stijl. De inspiratie die ik hierbij heb gebruikt is te zien onder
        aan de pagina (afbeelding 3 t/m 6). De voorkant van de flyer is
        hierboven te zien. Ik heb de letters ‘HANSJE’ in haar stijl
        geïllustreerd en samengevoegd tot één ontwerp.
        <br /><br />
        De flyer heeft een voor- en achterkant. Ik heb ervoor gekozen om op de
        achterkant van de flyer een andere stijl te gebruiken van Hansje
        (afbeelding 7). Verder heb ik op de achterkant van de flyer wat
        informatie gezet over Hansje van Halem.

        <br /><br />
      </div>
      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Dit was de eerste opdracht die ik heb gedaan binnen de minor Graphic
          Design. Ik vond het best spannend om mijn ontwerp te laten zien aan
          mijn coach en klasgenoten, omdat ik niet goed wist of ik op een
          bepaald ‘niveau’ zat, in vergelijking met de rest van de klas.
          Daarnaast vond ik het belangrijk dat de stijl van Hansje van Halem
          duidelijk terugkwam in mijn Graphic Hero flyer.
          <br />
          <br />
          Ik heb alleen maar positieve feedback gekregen van mijn klasgenoten en
          coach, wat ervoor heeft gezorgd dat ik meer zelfvertrouwen heb
          gekregen in mijn grafisch ontwerp ‘skills’. Verder vond ik deze
          opdracht erg leuk om te doen omdat ik alle onderdelen van de flyer heb
          ontworpen in Adobe Illustrator, wat een van mijn favoriete Adobe
          programma’s is.
        </p>
      </div>
      <a target="_blank" href="/img/minor/pages/graphic_hero_flyer-back.png">
        <img
          class="img-extra"
          src="/img/minor/pages/graphic_hero_flyer-back.png"
        />
      </a>
    </div>
    <p class="proces">PROCES</p>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero4.jpeg">
          <img
            src="/img/minor/pages/graphic-hero4.jpeg"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Hansje van Halem</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero8.jpeg">
          <img
            src="/img/minor/pages/graphic-hero8.jpeg"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Lowlands</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero2.jpeg">
          <img
            src="/img/minor/pages/graphic-hero2.jpeg"
            alt="Northern Lights"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Inspiratie</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero6.jpeg">
          <img
            src="/img/minor/pages/graphic-hero6.jpeg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Inspiratie</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero7.jpeg">
          <img
            src="/img/minor/pages/graphic-hero7.jpeg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">5. Inspiratie</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero5.jpeg">
          <img
            src="/img/minor/pages/graphic-hero5.jpeg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">6. Inspiratie</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/graphic-hero1.jpeg">
          <img
            src="/img/minor/pages/graphic-hero1.jpeg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">7. Inspiratie</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 25%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 350px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .reflection {
    position: absolute !important;
    left: 20% !important;
    max-width: 450px !important;
    margin-top: 250px !important;
  }
  .img-extra {
    max-width: 350px;
    margin-right: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 15%;
    margin-bottom: 50px;
  }
}

#section2 {
  margin-top: 50px;
  max-width: 450px;
}

.image-gallery {
  margin-left: 25% !important;
  margin-right: 25% !important;
}

div.gallery img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}
.proces {
  margin-top: 200px;
}

@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 50%;
    .reflection {
      margin-top: 280px !important;
      max-width: 400px !important;
    }
    .img-extra {
      margin-left: 2% !important;
    }
  }
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px;
    }
    .img-extra {
      max-width: 300px;
      margin-left: 10% !important;
    }

    margin-left: 50%;
    .reflection {
      margin-top: 350px !important;
      max-width: 300px !important;
    }
  }
  #section2 {
    max-width: 300px !important;
    margin-top: 50px;
  }
  .proces {
  margin-top: 500px;
}

}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
