<template>
  <div>
    <Navigation />
    <div class="header">
      <div class="headerimg">
        <p class ="header-title">PROCES PORTFOLIO</p>
        <img class ="gif" src="/img/icons/scroll.gif" alt="">
      </div>
      <div class="header-block">
        <div class="header-text">
          <div class="name">
            SUZANNE <br />
            RAAIJMAKERS
          </div>
          <div class="minor">Minor Graphic Design</div>
          <div class="year">2021-2022</div>
          <div class="social-icons">
            <a
              href="https://www.linkedin.com/in/suzanne-raaijmakers-28369a1a1/"
              target="_blank"
            >
              <img class="linkedIn" id = "img1" src="/img/icons/linkedin.png" />
            </a>
            <a
              href="https://www.instagram.com/_suzanne.r/"
              target="_blank"
              id="page-portfolio"
            >
              <img class="instagram" src="/img/icons/instagram.png" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="filtermenu">
      
      </div>
    </div> 

    <div class="content">
      <div class="container">
        <div class="gallery-container w-2 h-3">
          <div class="gallery-item">
            <div class="image">
               <a href="/minor-content/graphic-hero">
              <img
                id="visible"
                src="/img/minor/content-img/zw/hansjevanhalem.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/hansjevanhalem.png"
                alt="hansje"
              /></a>
            </div>
            <div class="text">GRAPHIC HERO FLYER</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-2">
          <div class="gallery-item">
            <div class="image">
              <a href="/minor-content/foodybag">
                <img
                  id="visible"
                  src="/img/minor/content-img/zw/mockup-3posters.jpeg"
                  alt="bierpull"/>
                <img
                  id="invisible"
                  src="/img/minor/content-img/mockup-3posters.jpeg"
                  alt="Foodybag"
              /></a>
            </div>
            <div class="text">TAS VAN VERSCHIL</div>
          </div>
        </div>

        <div class="gallery-container w-3 h-2">
          <div class="gallery-item">
            <div class="image">
               <a href="/minor-content/james-bond">
              <img
                id="visible"
                src="/img/minor/content-img/zw/jamesbond.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/jamesbond.png"
                alt="JamesBond"
              /></a>
            </div>
            <div class="text">JAMES BOND</div>
          </div>
        </div>

        <div class="gallery-container w-1 h-1">
          <div class="gallery-item">
            <div class="image">
              <a href="/minor-content/fotografie">
              <img
                id="visible"
                src="/img/minor/content-img/zw/IMG_0683.JPG"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/fotografie.JPG"
                alt="Fotografie"
              /></a>
            </div>
            <div class="text">FOTOGRAFIE</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-1">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/typografie">
              <img
                id="visible"
                src="/img/minor/content-img/zw/jeff-koons.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/jeff-koons.jpeg"
                alt="Jeffkoons"
              /></a>
            </div>
            <div class="text">TYPOGRAFIE</div>
          </div>
        </div>

        <div class="gallery-container w-3 h-2">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/transparent-it">
              <img
                id="visible"
                src="/img/minor/content-img/zw/transparent-it.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/transparent-it.png"
                alt="transparent-it"
              /></a>
            </div>
            <div class="text">TRANSPARENT-IT</div>
          </div>
        </div>

        <div class="gallery-container w-1 h-1">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/huisstijl">
              <img
                id="visible"
                src="/img/minor/content-img/zw/logo-suus_Tekengebied 1.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/logo-suus_Tekengebied 1.png"
                alt="logo-suus"
              /></a>
            </div>
            <div class="text">HUISSTIJL</div>
          </div>
        </div>

        <div class="gallery-container w-1 h-2">
          <div class="gallery-item">
            <div class="image">
              <a href="/minor-content/archetypes">
              <img
                id="visible"
                src="/img/minor/content-img/zw/wijnlabel.jpg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/wijnlabel.jpg"
                alt="wijnlabel"
              /></a>
            </div>
            <div class="text">ARCHETYPES</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-1">
          <div class="gallery-item">
            <div class="image">
              <a href="/minor-content/portretbewerking">
              <img
                id="visible"
                src="/img/minor/content-img/zw/portret-pixel-zw.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/portret-pixel.png"
                alt="transparent-it"
              /></a>
            </div>
            <div class="text">PORTRETBEWERKING</div>
          </div>
        </div>

        <div class="gallery-container w-3 h-2">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/photoshop-portret">
              <img
                id="visible"
                src="/img/minor/content-img/zw/portret-v1.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/portret-suzanne.png"
                alt="portret-suus"
              /></a>
            </div>
            <div class="text">PHOTOSHOP PORTRET</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-2">
          <div class="gallery-item">
            <div class="image">
               <a href="/minor-content/surrealistische-wereld">
              <img
                id="visible"
                src="/img/minor/content-img/zw/surrealistische_wereld.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/surrealistische_wereld.png"
                alt="portretglitch"
              /></a>
            </div>
            <div class="text">SURREALISTISCHE WERELD</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-1">
          <div class="gallery-item">
            <div class="image">
               <a href="/minor-content/fotografie-2">
              <img
                id="visible"
                src="/img/minor/content-img/zw/IMG_7284.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/fotografie2.png"
                alt="fotografie2"
              /></a>
            </div>
            <div class="text">FOTOGRAFIE METAFOREN</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-2">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/salvemundi">
              <img
                id="visible"
                src="/img/minor/content-img/zw/salve mundi.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/salve mundi.png"
                alt="samu"
              /></a>
            </div>
            <div class="text">SALVE MUNDI</div>
          </div>
        </div>

        <div class="gallery-container w-2 h-1">
          <div class="gallery-item">
            <div class="image">
                <a href="/minor-content/bierpull">
              <img
                id="visible"
                src="/img/minor/content-img/zw/MockUp-2.jpeg"
                alt="bierpull"
              />
              <img
                id="invisible"
                src="/img/minor/content-img/bierpull.png"
                alt="portret-pixel"
              /></a>
            </div>
            <div class="text">BIERPULL</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
//css content - grid

#invisible {
  display: none;
}
.image:hover {
  #invisible {
    display: block;
  }
  #visible {
    display: none;
  }
}

.content {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 50px;
}

.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 100px 300px;
  grid-gap: 10px;
  grid-auto-flow: dense;
}

.gallery-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-item .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  //transition: 0.5s ease-in-out;
}
// .gallery-item:hover .image img {
//   transform: scale(1.5);
// }

.gallery-item .text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  font-family: "Futura Bold";
  font-size: 14px;
  pointer-events: none;
  z-index: 4;
  //transition: 0.3s ease-in-out;
  // -webkit-backdrop-filter: blur(5px) saturate(1.8);
  //backdrop-filter: blur(1px) saturate(1);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}

.gallery-item:hover .text {
  opacity: 1;
  //animation: move-down 0.3s linear;
  padding: 1em;
}

.w-1 {
  grid-column: span 1;
}
.w-2 {
  grid-column: span 2;
}
.w-3 {
  grid-column: span 3;
}
.w-4 {
  grid-column: span 4;
}
.w-5 {
  grid-column: span 5;
}
.w-6 {
  grid-column: span 6;
}

.h-1 {
  grid-row: span 1;
}
.h-2 {
  grid-row: span 2;
}
.h-3 {
  grid-row: span 3;
}
.h-4 {
  grid-row: span 4;
}
.h-5 {
  grid-row: span 5;
}
.h-6 {
  grid-row: span 6;
}

@media screen and (max-width: 700px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .w-1,
  .w-2,
  .w-3,
  .w-4,
  .w-5,
  .w-6 {
    grid-column: span 1;
  }
}

@keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}

//css header
.header {
  .project1 {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 260px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    .projectnaam {
      font-family: "Proxima Nova Black";
      color: black;
      font-size: 18px;
      margin: 0;
      padding-top: 15px;
      margin-bottom: 5px;
    }
    .leeswijzer {
      text-decoration: none;
      color: black;
      margin: 0;
    }
    .leeswijzer:hover {
      text-decoration: underline;
    }
  }
  .headerimg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0px;
    background-color: black;
    .header-title{
      color: white;
      font-size: 100px;
      font-family: "Futura Bold";
      position: absolute;
      bottom: -28px;
      padding: 0;
      margin: 0;
    }
    .gif{
      width: 5%;
      position: absolute;
      right: 5%;
      bottom: 0;
    }
  }
  .header-block {
    height: 450px;
    position: absolute;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-text {
    font-size: 15px;
    text-align: right;
    color: white;
    font-family: "Proxima Nova Light";
    .name {
      font-family: "Futura Bold";
      font-size: 35px;
    }
    .minor {
      padding-top: 30px;
      font-family: "Proxima Nova Semibold";
      font-weight: 600;
      padding-bottom: 4px;
      font-size: 20px;
    }
    .social-icons {
      display: flex;
      right: 0;
      position: absolute;
      img { 
        width: 25px;
        margin-top: 15px;
      }
      #img1{
          margin-right: 15px;
        }
    }
  }
  .socials {
    position: absolute;
    top: 350px;
    right: 160px;
    img {
      height: 30px;
    }
    .linkedIn {
      padding-right: 20px;
    }
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: left;
  display: grid;
  margin-left: 30%;
  margin-right: 30%;
}

#flex-container4 {
  grid-template-columns: auto auto auto auto;
}

.center {
  display: flex;
  justify-content: center;
}
.bottom {
  margin-bottom: 30px;
}
//Filter
.filtermenu {
  margin-top: 110vh;
  margin-bottom: 50px;
  
}
</style>

<script>
import Navigation from "./../components/navigation.vue";
import Footer from "./../components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
};
</script>
