<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/typografie">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Typografie</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/james-bond">
      <p class="button-p">James Bond</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/pages/pixels1.png">
        <img class="img-page" src="/img/minor/pages/pixels1.png"
      /></a>
      <p class="title-page">PORTRETBEWERKING</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Binnen beide ontwerpen heb ik gebruik gemaakt van hetzelfde portret, zie
        afbeelding 1 onderaan de pagina. <br /><br />
        <b>Adobe Illustrator</b><br />
        In de afbeelding hiernaast is mijn pixel portret te zien. Dit ontwerp heb ik
        gemaakt tijdens een workshop van Adobe Illustrator. Hierbij heb ik mijn
        huidige portret gebruikt en daar een pixel variant van gemaakt. In
        afbeelding 2 onderaan de pagina is te zien dat we het canvas hebben
        opgedeeld in een raster met kleine vierkantjes. Achter dit raster had ik
        mijn portret gezet, zodat ik deze gemakkelijk kon overtrekken. Ik heb
        als eerste de omlijning gemaakt van mijn hoofd, gezichtselementen en mijn
        kleding. Vervolgens heb ik deze vakje voor vakje ingekleurd waarbij ik
        de ‘pipet-tool’ heb gebruikt om de kleuren uit mijn portret exact over
        te kunnen nemen in mijn pixel portret.
      </div>
      <div class="text" id="section2">
        <b>Adobe Photoshop</b><br />
        In de afbeelding hiernaast is mijn glitch portret te zien. Dit ontwerp
        heb ik gemaakt bij een workshop over Adobe Photohop. Bij dit ontwerp heb
        ik hetzelfde portret gebruikt als bij het pixel portret, maar het
        resultaat van beide ontwerpen is lijkt totaal niet meer op elkaar,
        terwijl het dezelfde afbeelding is. Om het glitch effect te krijgen heb
        ik met de ‘selectie-tool’ kleine stukjes van mijn portret moeten
        selecteren en deze vervolgens een stukje verplaatsen. Hierdoor krijg je
        dat de afbeelding niet meer één geheel vormt, waardoor het lijkt alsof
        er een ‘glitch’ plaats vindt. Ook met de kleuren is gespeeld, zodat van
        de RGB kleuren alleen nog maar de rode en blauwe kleur terug te zien
        zijn, voor extra effect.

        <br /><br />
      </div>
      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Ik vind beide ontwerpen erg goed gelukt. Door de Adobe Advanced
          workshops heb ik ontzettend veel geleerd. Allemaal verschillende trucjes,
          technieken, kennis en vaardigheden welke ik in de toekomst in mijn
          eigen projecten kan toepassen.
          <br /><br />
          Voordat ik begon aan deze minor heb ik alleen maar gebruik gemaakt van
          de basisfuncties in de Adobe programma’s. Door deze workshops blijkt
          er zo veel meer mogelijk dan ik wist, dus ik heb hier ontzettend veel
          van geleerd.
        </p>
      </div>
      <a target="_blank" href="/img/minor/pages/glitch1.jpg">
        <img class="img-extra" src="/img/minor/pages/glitch1.jpg" />
      </a>
    </div>
    <p class="proces">PROCES</p>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/pixels0.jpeg">
          <img
            src="/img/minor/pages/pixels0.jpeg"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Portret</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/pixels2.png">
          <img
            src="/img/minor/pages/pixels2.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Proces</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .reflection {
    position: absolute !important;
    left: 20% !important;
    max-width: 500px !important;
    margin-top: 450px !important;
  }
  .img-extra {
    max-width: 400px;
    margin-right: 0px;
    margin-top: 230px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 22%;
    margin-bottom: 200px;
  }
}

#section2 {
  margin-top: 230px;
  max-width: 500px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

div.gallery img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 50%;
    .img-page {
      max-width: 350px !important;
    }
    .reflection {
      margin-top: 450px !important;
      max-width: 350px !important;
    }
    .img-extra {
      margin-left: 20% !important;
      margin-top: 150px;
      max-width: 350px !important;
    }
  }
  #section2 {
    max-width: 350px !important;
    margin-top: 150px;
  }
  .proces {
    margin-top: 400px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px;
    }
    margin-left: 50%;
    .img-extra {
      margin-left: 15% !important;
      max-width: 350px !important;
      margin-top: 150px;
    }
    .reflection {
      margin-top: 450px !important;
      max-width: 350px !important;
    }
  }

  #section2 {
    max-width: 350px !important;
    margin-top: 150px;
  }
  .proces {
    margin-top: 400px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
