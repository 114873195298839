import { render, staticRenderFns } from "./huisstijl.vue?vue&type=template&id=5ddc4073&scoped=true&"
import script from "./huisstijl.vue?vue&type=script&lang=js&"
export * from "./huisstijl.vue?vue&type=script&lang=js&"
import style0 from "./huisstijl.vue?vue&type=style&index=0&id=5ddc4073&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ddc4073",
  null
  
)

export default component.exports