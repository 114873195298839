<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/photoshop-portret">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Photoshop project</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/fotografie-2">
      <p class="button-p">Fotografie metaforen</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/surrealistische_wereld.png">
      <img
        class="img-page"
        src="/img/minor/content-img/surrealistische_wereld.png"
      /></a>
      <p class="title-page">SURREALISTISCHE WERELD</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Ik vond dit een van de leukste workshops binnen de minor Graphic Design.
        Deze workshop is gegeven door Jeroen van den Berg en was een advanced
        les van het programma Adobe Photoshop. We hebben een surrealistische
        wereld gemaakt, zoals te zien is in de afbeelding hiernaast. Wat ik zo
        gaaf vond aan deze workshop is dat we vijf verschillende foto’s hebben
        gecombineerd tot één eindwerk (zie afbeeldingen onderaan de pagina),
        waarbij het lijkt alsof de foto’s altijd al bij elkaar hoorde.
        <br /><br />
        In zes verschillende stappen zijn we tot deze surrealistische wereld
        gekomen. Hierbij hebben we gebruik gemaakt van het uitknippen van
        elementen, het verdraaien van afbeeldingen, het toevoegen van schaduwen
        en gloed, het zelf tekenen van elementen voor een realistisch effect
        (grassprieten voor en naast de auto), het toevoegen van lichtinval op de
        persoon en als detail het lichtpuntje op de neus van de auto. Kortom,
        naast het samenvoegen van de vijf verschillende foto’s zijn er veel
        details toegevoegd om deze surrealistische wereld toch realistisch te
        laten overkomen.
      </div>

      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Deze workshop was vrij tegen het einde van de minor. Over het algemeen
          gingen alle workshops binnen de minor erg snel, aangezien je volledig
          moest multitasken tijdens de les (de uitleg en het zelf proberen ging
          door elkaar). Hierdoor was het soms tijdens de workshops erg lastig om
          het te volgen, aangezien je dan net een detail in de uitleg had
          gemist, waardoor je niet meer wist waar je een bepaalde knop of
          functie binnen het programma zelf kon terugvinden om verder te gaan.
          <br /><br />
          Bij deze workshop heb ik daar niet echt last van gehad. Het scheelde
          dat we al eerder Adobe Photoshop lessen hadden gekregen, waardoor alle
          handelingen niet compleet nieuw meer waren. Hierdoor was het goed te
          volgen en heb ik een gaaf eindontwerp kunnen realiseren.
        </p>
      </div>
    </div>
                 <p class = "proces">PROCES</p>
    <div class="image-gallery" id="gallery1">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/sw-1.jpg">
          <img
            src="/img/minor/pages/sw-1.jpg"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Content</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/sw-2.jpg">
          <img
            src="/img/minor/pages/sw-2.jpg"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Content</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/sw-5.png">
          <img
            src="/img/minor/pages/sw-5.png"
            alt="Northern Lights"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Content</div>
      </div>
    </div>
    <div class="image-gallery" id="gallery2">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/sw-3.jpg">
          <img
            src="/img/minor/pages/sw-3.jpg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Content</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/sw-4.jpg">
          <img
            src="/img/minor/pages/sw-4.jpg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">5. Content</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  .reflection {
    margin-top: 40px !important;
  }
  .img-page {
    max-height: 800px;
  }
}

#section2 {
  margin-top: 40px;
  max-width: 500px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
}
#gallery1 {
  margin-bottom: 0px !important;
}

div.gallery img {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
