<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/portretbewerking">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Portretbewerking</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/archetypes">
      <p class="button-p">Archetypes</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/jamesbond.png">
        <img class="img-page" src="/img/minor/content-img/jamesbond.png"
      /></a>
      <p class="title-page">JAMES BOND</p>
      <p class="description">Commercieel</p>
      <div class="text">
        Samen met mijn projectgroep Maze Design heb ik tijdens het commerciële
        project een opdracht gedaan voor Twan Arts. Twan is een schrijver voor
        ‘Voor Dummies’ en heeft in 2015 een boek over James Bond geschreven. Nu
        (7 jaar later) wil Twan een uitgebreidere en meer geactualiseerde
        uitgave van het boek uitbrengen welke ‘De wereld van James Bond’ zal
        gaan heten. Twan heeft het boek geschreven (in Word) en heeft aan ons
        gevraagd om dit boek mooi op te maken zodat deze uitgebracht kan worden.
        Binnen dit project was ik samen met Pleun Hertroijs verantwoordelijk
        voor de opmaak van alle tekst en de plaatsing van de iconen, daarnaast
        heb ik net als de andere groepsleden zelf ook iconen gemaakt.
        <br /><br />
        Het boek bestaat uit vijf boekdelen waaronder verschillende hoofdstukken
        vallen. Per hoofdstuk hebben wij een icoon toegevoegd welke visualiseert
        waar het hoofdstuk overgaat. In afbeelding 1, onderaan de pagina, is de
        layout van de boekdelen te zien en in afbeelding 2 de layout van een
        bijbehorend hoofdstuk. Afbeelding 3 is het icoon van het hoofdstuk “De
        geboorte van James Bond” en heb ik ontworpen.
      </div>
      <div class="text" id="section2">
        Naast de centrale iconen hebben we ook het filmfragment icoon ontworpen
        (dit icoon heb ik ook gemaakt), zie afbeelding 4. Zoals in de afbeelding
        te zien is dit icoon een uitsneden van een deel van het ontwerp van de
        openingsscène in elke Bond-film. Het filmfragment icoon wordt gebruikt
        om een stuk tekst in het boek, waar een specifieke scene in een film
        wordt toegelicht, uit te lichten. Naast het filmfragment icoon hebben we
        ook een opsommingsteken ontworpen, welke lijkt op een vizier in een
        pistool.
        <br /><br />
        Twan maakt in zijn boek gebruik van tekstvakken waarin hij een bepaald
        onderwerp of een bepaalde personage/acteur toelicht. De tekstvakken
        hebben we zo ontworpen dat deze goed opvallen in de tekst, maar wel in
        dezelfde stijl blijven (zie afbeelding 5).

        <br /><br />
        Ik ben erg tevreden met het eindresultaat en vind het heel gaaf dat ik
        een bijdrage heb mogen leveren aan dit boek wat ook echt uitgebracht zal
        gaan worden. Het gaafste in het boek is dat Twan ons een ‘shout out’
        heeft gegeven in zijn voorwoord.
        <br /><br />
        <i>
          “Ook een speciaal woord van dank voor het team achter Maze Design: Tim
          de Bruijn, Lisanne Drinhuyzen, Martijn van der Heijden, Pleun
          Hertroijs en Suzanne Raaijmakers. Zij hebben de binnenzijde van dit
          boek ontworpen. Mocht men voor toekomstige Bond-posters een grafisch
          ontwerper zoeken, dan is Maze Design de aangewezen partij.”</i
        >

        <br /><br />
      </div>
      
      <a target="_blank" href="/img/minor/pages/Bond-6.png">
        <img class="img-extra" src="/img/minor/pages/Bond-6.png" />
      </a>
    </div>
        <p class="proces">PROCES</p>
    <div class="image-gallery" id = "gallery1">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/bond-1.png">
          <img
            src="/img/minor/pages/bond-1.png"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Boekdeel</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/bond-2.png">
          <img
            src="/img/minor/pages/bond-2.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Hoofdstuk</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/Bond-3.png">
          <img
            src="/img/minor/pages/Bond-3.png"
            alt="Northern Lights"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Icoon</div>
      </div>
    </div>
     <div class="image-gallery" id = "gallery2"> 
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/Bond-4.png">
          <img
            src="/img/minor/pages/Bond-4.png"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Filmfragment</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/Bond-5.png">
          <img
            src="/img/minor/pages/Bond-5.png"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">5. Tekstvak</div>
      
    </div>
    </div>
    <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          James Bond was de gaafste opdracht die ik heb gedaan tijdens het
          commerciële project. We hebben met de groep een boek, wat bestond uit
          puur platte tekst, helemaal mogen opmaken en illustreren. Op het begin
          dacht ik: “dat hebben we zo gedaan”, aangezien de platte tekst maar
          uit 150 pagina’s bestond. Maar toen had ik me nog niet gerealiseerd
          dat we van A4 naar A5 gingen, waardoor we uiteindelijk op zo’n 280
          pagina’s uit zijn gekomen.
          <br /><br />
          Het enige aspect waarvan ik dacht: “dat had beter gekund”, was de
          communicatie met Twan. Deze communicatie werd gedaan via de mail,
          waardoor sommige dingen niet geheel duidelijk over zijn gekomen. Zo
          begon dat al met de indeling van het boek: Wat zijn boekdelen? Wat
          zijn hoofdstukken? Waar moet er wel en niet een illustratie of icoon?
          Etc. Verder was het jammer dat Twan aan het begin van het project had
          aangegeven dat hij veel illustraties en ontwerpen wil terug zien komen
          in de opmaak van het boek, maar na mate het project volgde wilde hij
          steeds minder illustraties en begon het boek meer te lijken op gewoon
          een roman die je in de bibliotheek kunt vinden.
          <br /><br />
          Met de groep samen werken aan dit project is goed gegaan, iedereen had
          zijn/haar eigen taak en het is snel samen tot één geheel gekomen.
          Zodra het boek in de winkel ligt zal ik zeker een eigen exemplaar
          aanschaffen. In het voorwoord van het boek staan onze namen, want wij
          zijn verantwoordelijk geweest voor de hele opmaak. Een boek in de
          winkel waaraan ik heb mogen meewerken en waarin mijn naam vermeld
          staat, dat is echt super gaaf!
        </p>
      </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 55%;
  margin-right: 20%;
  margin-bottom: 0;
   margin-top: 150px;
  color: black;
  .img-page {
    max-width: 450px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  
  .img-extra {
    max-width: 400px;
    margin-right: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 0%;
    margin-bottom: 50px;
  }
}
.reflection {
    margin-right: 20%;
    margin-left: 20%;
    background-color: black;
    margin-top: 20px;
    margin-bottom: 50px;
    p {
      color: white;
      font-family: "Proxima Nova Light";
      text-align: left;
      font-size: 14px;
      text-align: justify;
      padding: 0px 150px 40px 150px;
    }
    h2 {
      color: white;
      padding-top: 25px;
      font-family: Futura;
      font-weight: 600;
    }
  }

#section2 {
  margin-top: 50px;
  max-width: 450px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
 
}

div.gallery img {
  //width: auto!important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#gallery2{
    div.gallery img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}
}
.proces{
  margin-top: 200px;
}

@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 50%;
    .img-page {
      max-width: 350px !important;
    }
    .reflection {
      margin-top: 450px !important;
      max-width: 350px !important;
    }
    .img-extra {
      margin-left: 20% !important;
      margin-top: 50px;
      max-width: 350px !important;
    }
  }
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 300px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px;
    }
    margin-left: 50%;
    .img-extra {
      margin-left: 12% !important;
      max-width: 350px !important;
      margin-top: 50px;
    }
    .reflection {
      margin-top: 450px !important;
      max-width: 350px !important;
    }
  }

  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 300px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
