<template>
  <div id="app">
    <router-view />
  </div>

</template>

<style lang="scss">
@font-face {
  font-family: "Varela";
  src: local("Varela"), url(./fonts/VarelaRound-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova Black";
  src: local("Proxima Nova Black"), url(./fonts/ProximaNovaA-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova Light";
  src: local("Proxima Nova Light"), url(./fonts/ProximaNovaA-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: local("Proxima Nova Semibold"), url(./fonts/ProximaNovaA-Semibold.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Bold";
  src: local("Futura Bold"), url(./fonts/Futura-Bold.ttf) format("truetype");
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
body{
  margin: 0;
  padding: 0;
  // background-color: rgba(175, 175, 175, 0.05);
  background-color: #f6f2f1;
  letter-spacing: 0.5px;
  
}

</style>


<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  components: { Navigation, Footer },
};
</script>
