<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/foodybag">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Tas van verschil</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/huisstijl">
      <p class="button-p">Huisstijl</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
        <a target="_blank" href="/img/minor/pages/fotografie1-2.JPG">
      <img class="img-page" src="/img/minor/pages/fotografie1-2.JPG" /></a>
      <p class="title-page">FOTOGRAFIE</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        De opdracht van de eerste fotografie workshop was het maken van een
        serie foto’s die een verhaal vertellen over een belangrijk persoon of
        onderwerp in jouw leven. Ik heb hierbij gekozen voor het onderwerp:
        Herfst. Herfst is mijn favoriete seizoen van het jaar. Dit seizoen zit
        bomvol met mooie kleuren en is prachtig om te fotograferen. Daarnaast
        doet mij dit denken aan de herfstvakanties van vroeger met mijn familie,
        waar we door de bossen gingen lopen en waar ik ook veel foto’s heb
        gemaakt van de herfst.
        <br /><br />
        Voor mijn fotoserie heb ik de herfst gefotografeerd in mijn buurt. Ik
        heb gebruik gemaakt van een spiegelreflexcamera waarbij ik zelf alle
        instellingen heb gedaan van de scherpte/diepte, sluitertijd en
        ISO-waarde.

        <br /><br />
      </div>
      <div class="text" id="section2">
        Ik heb foto's in drie verschillende categorieën gemaakt: <br />
        1. Foto’s met strijklicht (zie afbeelding 1 en 2). Hierbij heb ik
        gekeken naar de lichtinval van de zon in de bladeren. Ik heb zowel van
        ‘licht van achteren’ als van ‘licht van voren’ gebruik gemaakt.
        <br /><br />
        2. Foto’s met reflectie en (bevroren) beweging (zie afbeelding 3 en
        4). In beide afbeeldingen is de reflectie van de herfst en de lucht
        terug te zien. In afbeelding 4 heb ik geprobeerd om een beweging vast te
        leggen, welke goed is gelukt maar wel als ‘bevroren’ overkomt en niet
        echt meer beweegt.
        <br /><br />
        3. Foto’s vanuit een ander perspectief (zie afbeelding 5 en 6). Ik heb
        in beide afbeelidngen een paddenstoel gefotografeerd (toch wel een
        typisch kenmerk van de herfst). De eerste afbeelding is een close-up van
        de zijkant van een paddenstoel en de tweede afbeelding is een close-up
        van de onderkant van een paddenstoel. Deze laatste is een perspectief
        die niet veel mensen zien van een paddenstoel.

        <br /><br />
      </div>
      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Ik vond het best lastig om mijn eigen ‘verhaal’ te vinden doormiddel
          van fotografie. Ik wist dat ik de herfst wilde vastleggen omdat ik dit
          zelf ook gewoon heel leuk vind om te fotograferen, maar naast het feit
          dat het mijn favoriete seizoen is had ik niet echt een verhaal
          hierbij. Tijdens het presenteren van mijn fotografie in de klas kwam
          ik er snel achter dat mijn klasgenoten vonden dat mijn gevoel voor de
          herfst al een verhaal vormde zonder dat ik dat zelf wist.
          <br /><br />
          Om de foto met de beweging beter vast te leggen, kan ik in het vervolg
          met andere standen op de camera en een beweegeffect werken. Een tip
          die ik heb meegekregen vanuit de minor.
        </p>
      </div>
    </div>
            <p class = "proces">PROCES</p>
    <div class="image-gallery" id="gallery1">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/fotografie1-1.JPG">
          <img
            src="/img/minor/pages/fotografie1-1.JPG"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Strijklicht</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/test/fotografie1-5.jpg">
          <img
            src="/img/minor/test/fotografie1-5.jpg"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Strijklicht</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/fotografie1-3.JPG">
          <img
            src="/img/minor/pages/fotografie1-3.JPG"
            alt="Northern Lights"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Reflectie</div>
      </div>
    
      <div class="gallery">
        <a target="_blank" href="/img/minor/test/fotografie1-6.jpg">
          <img
            src="/img/minor/test/fotografie1-6.jpg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Reflectie (en beweging)</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/fotografie1-2.JPG">
          <img
            src="/img/minor/pages/fotografie1-2.JPG"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Perspectief</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/test/fotografie1-4.jpg">
          <img
            src="/img/minor/test/fotografie1-4.jpg"
            alt="Mountains"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Perspectief</div>
      </div>
    </div>
<br><br>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  .reflection {
    margin-top: 80px !important;
  }
}

#section2 {
  margin-top: 80px;
  max-width: 450px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
}
#gallery1{
    margin-bottom: 0px !important;
}

div.gallery img {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
 
}
@media only screen and (max-width: 1500px) {
  .section {
    .reflection {
    margin-top: 20px !important;
  }}
  #section2 {
  margin-top: 20px !important;
}

@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px;
    }

    margin-left: 50%;
  
  }
  #section2 {
    max-width: 300px !important;
    margin-top: 50px;
  }
  
}
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
