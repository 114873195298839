<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/transparent-it">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Transparent-IT</p>
    </router-link>
    <router-link
      class="button"
      id="next"
      to="/minor-content/surrealistische-wereld"
    >
      <p class="button-p">Surrealistische wereld</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/pages/photoshop1.png">
        <img class="img-page" src="/img/minor/pages/photoshop1.png"
      /></a>
      <p class="title-page">PHOTOSHOP PORTRET</p>
      <p class="description">Eigen werk</p>
      <div class="text">
        In de afbeelding hiernaast en hieronder zijn twee van mijn ‘probeersels’
        in photoshop te zien. Ik heb ervoor gekozen om deze afbeeldingen
        (ondanks dat ik er nog niet helemaal tevreden mee ben) toch in mijn
        portfolio te zetten om mijn proces te kunnen laten zien. Ik heb namelijk
        met deze mini opdracht voor mezelf, voor de eerste keer in mijn leven
        Adobe Photoshop gebruikt. Ik heb dit programma wel eerder al eens
        gebruikt om de kleur van een icoon om te keren (dus van zwart wit te
        maken en vice versa), maar nog nooit gebruikt voor echte fotobewerking.
        <br /><br>
        Het doel van deze mini opdracht was om een afbeelding van mezelf te
        combineren met een andere afbeelding en zo een gaaf effect uit te
        proberen. Ik vind zelf dat dit voor een eerste keer erg goed is gelukt,
        ondanks dat het uitsnede van mijn eigen silhouet nog stukken beter had
        gekund.
      </div>

      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Als je dit werk vergelijkt met mijn eindontwerp van het conceptuele
          project, zie tas van verschil, dan zie je dat ik op het gebied van
          photoshop echt stappen heb gemaakt deze minor. Toen ik de minor begon
          had ik nog nooit gebruik gemaakt van dit programma, omdat ik totaal
          niet wist hoe dit allemaal werkte. Door alle workshops binnen de minor
          en het volgen van tutorial video’s ben ik hier beter in geworden, wat
          terug te zien is in mijn werk.
        </p>
      </div>
      <a target="_blank" href="/img/minor/pages/photoshop2.png">
        <img class="img-extra" src="/img/minor/pages/photoshop2.png" />
      </a>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .reflection {
    position: absolute !important;
    left: 20% !important;
    max-width: 500px !important;
    margin-top: 160px !important;
  }
  .img-extra {
    max-width: 300px;
    margin-right: 0px;
    margin-top: 160px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 40%;
    margin-bottom: 200px;
  }
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

div.gallery img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 350px !important;
    }
    margin-left: 50%;
    .img-extra {
      margin-left: 22% !important;
      max-width: 300px !important;
      margin-top: 50px;
      margin-bottom: 100px !important;
    }
    .reflection {
      margin-top: 50px !important;
      max-width: 350px !important;
    }
  }

  #section2 {
    max-width: 300px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 100px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
