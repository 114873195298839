<template>
  <div>
    <Navigation />
    <router-link
      class="button"
      id="back"
      to="/minor-content/surrealistische-wereld"
    >
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Surrealistische wereld</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/bierpull">
      <p class="button-p">Bierpull</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/fotografie2.png">
        <img class="img-page" src="/img/minor/content-img/fotografie2.png"
      /></a>
      <p class="title-page">FOTOGRAFIE METAFOREN</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        In de afbeelding hierboven is mijn foto te zien van de tweede workshop
        Fotografie. Hierbij was het doel om doormiddel van een metafoor (een
        stijlfiguur, waarbij de vorm van taalgebruik niet letterlijk is maar
        figuurlijk) een verhaal vast te leggen. Ik heb ervoor gekozen om het
        verhaal “Trust the proces” vast te leggen. Met de ‘troep’ en
        verschillende materialen rechtsboven aan de foto wilde ik chaos en
        proces visualiseren. Als je de vorm volgt van rechtsboven naar
        linksonder dan zie je dat langzaam alles op zijn plek valt en dat het
        eindresultaat prachtig is (het rode hart).
      </div>
    </div>
    <div class="reflection">
      <h2>REFLECTIE</h2>
      <p>
        Dit was een kleine workshop welke op school heeft plaats gevonden.
        Binnen een uurtje heb ik een verhaal bedacht, vastgelegd en met
        photoshop aangepast zodat mijn concept het beste naar voren kwam. Ik
        vond het leuk om te zien dat in zo’n korte tijd toch iets krachtigs en
        moois neer te zetten is. Door deze workshop heb ik echt nagedacht over
        de compositie van de elementen op mijn afbeelding, om daar vervolgens
        mijn verhaal mee te kunnen vertellen.
      </p>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
 margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

 
}
.reflection {
  margin-right: 20%;
  margin-left: 20%;
  background-color: black;
  margin-top: 130px;
  margin-bottom: 120px;
  p {
    color: white;
    font-family: "Proxima Nova Light";
    text-align: left;
    font-size: 14px;
    text-align: justify;
    padding: 0px 150px 40px 150px;
  }
  h2 {
    color: white;
    padding-top: 25px;
    font-family: Futura;
    font-weight: 600;
  }
}

#section2 {
  margin-top: 50px;
  max-width: 500px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
  margin-top: 100px !important;
}

div.gallery img {
  width: auto !important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#gallery2 {
  div.gallery img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    object-position: 50% 50%;
    margin-right: 20px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .img-page {
    max-width: 350px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
