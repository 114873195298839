<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/archetypes">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Archetypes</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/photoshop-portret">
      <p class="button-p">Photoshop portret</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/transparent-it.png">
        <img class="img-page" src="/img/minor/content-img/transparent-it.png"
      /></a>
      <p class="title-page">TRANSPARENT-IT</p>
      <p class="description">Commercieel</p>
      <div class="text">
        Tjerk van Gerwen heeft zijn eigen zzp Transparent-IT. Tjerk heeft in
        2019 een branding voor Transparent-IT gedaan, nu twee jaar verder is hij
        toe aan een nieuw design. Tijdens mijn commerciële project heb ik als
        individuele opdracht een aantal producten gemaakt voor Transparent-IT.
        Deze producten waren als volgt: Een redesign van het huidige logo, een
        style guide (huisstijlhandboek) van de branding van zijn bedrijf, een
        redesign van zijn huidige visitekaartje op basis van de style guide en
        een flyer voor het werven van nieuwe potentiële klanten.
        <br /><br />
        <b>Logo</b><br />
        Ik heb een redesign gedaan van het huidige logo van Transparent-IT (zie
        afbeelding 1 onder aan de pagina). Ik heb bij de redesign de kleuren van
        het logo aangepast, het lettertype en het ‘slot’ icoontje in het midden.
        In afbeelding 2 is het nieuwe logo te zien van Transparent-IT
        <br /><br />
      </div>
      <div class="text" id="section2">
        <b>Visitekaartje</b><br />
        In afbeelding 3 is de het huidige visitekaartje te zien van
        Transparent-IT. Ik vind het huidige visitekaartje erg druk en niet één
        geheel, aangezien er verschillende lettertypes zijn gebruikt. Verder ben
        ik zelf niet echt fan van het ‘netwerk’ element aan de zijkanten van het
        kaartje. In mijn eigen ontwerp van het visitekaartje heb ik meer rust
        toegepast en laat ik de kleuren uit de huisstijl terugkomen (zie
        brandguide). In de mock-up hiernaast is het mijn redesign van het
        visitekaartje van Transparent-IT te zien. Ik heb ook een stijlelement
        toegevoegd om de lege ruimtes in de hoeken van het visitekaartje wat op
        te vullen.
        <br /><br />
        <b>Brandguide</b><br />

        Transparent-IT had nog geen huidige brandguide. Samen met Tjerk heb ik
        overlegd welke onderdelen hij graag terug wil zien komen in zijn
        brandguide. Deze onderdelen waren als volgt: kleurenpalet, logo en
        lettertypes. In afbeelding 4 t/m 7 zijn een aantal pagina’s uit de
        brandguide te zien. In deze pagina’s heb ik het kleurenpalet beschreven
        en het logo uitgelegd. In de afbeelding hieronder zijn de gekozen
        lettertypes voor Transparent-IT te zien.

        <br /><br />
      </div>
      <a target="_blank" href="/img/minor/pages/mockup2.png">
        <img class="img-extra" src="/img/minor/pages/mockup2.png" />
      </a>
      <a target="_blank" href="/img/minor/pages/huisstijl-mockup2.png">
        <img class="img-page" src="/img/minor/pages/huisstijl-mockup2.png"
      /></a>
      <div class="text" id="section3">
        <b>Flyer</b><br />
        Zie afbeelding 8 voor de voorkant en afbeelding 9 voor de achterkant van
        de flyer. In de flyer staat in informatie beschreven over
        Transparent-IT, de diensten die Tjerk levert en zijn tarieven. Deze
        flyer is gemaakt in de huisstijl van Transparen-IT.

        <br /><br />

        <div class="reflection">
          <h2>REFLECTIE</h2>
          <p>
            Het was een groot project. Ik heb vier verschillende producten in
            verschillende stappen ontworpen, aangepast en uiteindelijk
            opgeleverd in de huisstijl van Transparent-IT. Ik heb veel ervaring
            opgedaan, zeker doordat ik feedback heb gekregen van een echte
            ‘klant’ en deze zo goed mogelijk heb proberen te verwerken in de
            producten.
            <br /><br />
            Ik vond het een gaaf project en ik vind het vooral echt heel tof dat
            alle producten die ik heb ontworpen ook echt gebruikt zullen gaan
            worden door Tjerk. Het visitekaartje en de flyer zullen worden
            gedrukt en verspreid door Nederland om klanten te werven. Het logo
            zal op de website en bijvoorbeeld offertes en facturen van
            Transparent-IT worden gebruikt.
            <br /><br />
            Tjerk heeft mij gevraagd of ik nog meer producten voor hem wil
            maken, zoals een e-mailhandtekening en het ontwerpen van
            merchandise. Dit betekent dat hij echt blij is met mijn werk en dat
            hij mij als ontwerper van de branding van Transparent-IT heeft
            aangewezen en dat is super gaaf dat ik dit heb over heb gehouden aan
            dit commerciële project van de minor.
          </p>
        </div>
      </div>
    </div>
          <p class = "proces">PROCES</p>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-1.png">
          <img
            src="/img/minor/pages/IT-1.png"
            alt="Cinque Terre"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Huidig logo</div>
      </div>

      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-2.png">
          <img id ="extra"
            src="/img/minor/pages/IT-2.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Nieuw logo</div>
      </div>
        </div>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-3.png">
          <img 
            src="/img/minor/pages/IT-3.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Huidig visitekaartje</div>
      </div>
  
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-4.png">
          <img
            src="/img/minor/pages/IT-4.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Brandguide</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-5.png">
          <img
            src="/img/minor/pages/IT-5.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">5. Brandguide</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-6.png">
          <img
            src="/img/minor/pages/IT-6.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">6. Brandguide</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-7.png">
          <img
            src="/img/minor/pages/IT-7.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">7. Brandguide</div>
      </div>
        </div>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-8.png">
          <img
            src="/img/minor/pages/IT-8.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">8. Flyer voorkant</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/IT-9.png">
          <img
            src="/img/minor/pages/IT-9.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">9. Flyer achterkant</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .img-extra {
    max-width: 400px;
    margin-right: 0px;
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 18%;
    margin-bottom: 100px;
  }

  .reflection {
    background-color: black;
    margin-top: 0px;
    p {
      color: white;
      font-family: "Proxima Nova Light";
      text-align: left;
      font-size: 14px;
      text-align: justify;
      padding: 0px 40px 40px 40px;
    }
    h2 {
      color: white;
      padding-top: 25px;
      font-family: Futura;
      font-weight: 600;
      text-align: center;
    }
  }
}

#section2 {
  margin-top: 50px;
  max-width: 450px;
  margin-bottom: 50px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

div.gallery img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#extra{
  width: 80% !important;
}
@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 50%;
    .img-page {
      max-width: 350px !important;
    }
    
    .img-extra {
      margin-left: 18% !important;
      margin-top: 50px;
      max-width: 350px !important;
      margin-bottom: 200px !important;
    }
  }
  .reflection {
      margin-top: 0px !important;
    }
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 100px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px !important;
    }
    margin-left: 50%;
    .img-extra {
      margin-left: 15% !important;
      max-width: 300px !important;
      margin-top: 50px;
      margin-bottom: 300px !important;
    }
    .reflection {
      margin-top: 0px !important;
      max-width: 400px !important;
    }
  }

  #section2 {
    max-width: 300px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 100px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
