<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/james-bond">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">James Bond</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/transparent-it">
      <p class="button-p">Transparent-IT</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/wijnlabel.jpg">
        <img class="img-page" src="/img/minor/content-img/wijnlabel.jpg"
      /></a>
      <p class="title-page">ARCHETYPES</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Tijdens de workshop archetypes en design heb ik de opdracht gekregen om
        een wijnlabel te ontwerpen op basis van een zelfgekozen archetype. Voor
        deze opdracht heb ik gekozen voor het archetype Magician. Kenmerken van
        dit archetype zijn “van het onmogelijke het mogelijke maken” en “dromen
        worden werkelijkheid”.
        <br /><br />
        Om deze stijl terug te laten komen in mijn wijnlabel ontwerp, heb ik
        ervoor gekozen om elementen van Disney terug te laten komen. Als ik denk
        aan dromen werkelijkheid laten worden en van het onmogelijke het
        mogelijke maken dan denk ik gelijk aan Disney. De disneyfilms nemen je
        mee in een verhaal waar alles mogelijk is en waar dromen werkelijkheid
        worden. Verder kan de stijl van de Magician terugkomen in mijn ontwerp
        door het kleurenpalet hiervan te gebruiken. Hierbij gaat het om
        paars/blauw/roze tinten.
        <br /><br />
       
      </div>
      <div class="text" id="section2">
           In mijn ontwerp (zie afbeelding hiernaast) komt het disneykasteel terug
        en heb ik gebruik gemaakt van een quote van Walt Disney (oprichter
        Disney, filmproducent). Daarnaast heb ik ook het kleurenpalet van de
        Magician laten terugkomen in de achtergrond.
        <br><br>
        Naast het ontwerp van het wijnlabel heb ik ook een onliness statement
        geschreven om daarmee de unieke punten van mijn ontwerp duidelijk te
        maken. Mijn onlineness statement was als volgt:
        <br /><br /><i>
          De enige rosé <br />
          Die een magisch gevoel geeft <br />
          Voor creatievelingen <br />
          In heel Europa <br />
          Die het onmogelijke mogelijk willen maken <br />
          In een tijd waar dromen werkelijkheid kunnen worden <br
        /></i>
      </div>
     
      <a target="_blank" href="/img/minor/pages/archetypes.jpg">
        <img class="img-extra" src="/img/minor/pages/archetypes.jpg" />
      </a>
    </div>
 <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Ik vond dit een erg leuke opdracht om te doen. Door je te verdiepen in
          een archetype en vervolgens daar ook een uitwerking mee te maken, leer
          je het archetype goed kennen en ook herkennen in andere ontwerpen (en
          bedrijven).
          <br /><br />
          De feedback van Joris van Dooren en Coen Luijten op mijn wijnlabel
          ontwerp was als volgt: <br />
          “Leuk!!!” en “Fraai hoor! Een echte Magician met een randje creator
          (of andersom) als je het mij vraagt!” Wat ik leuk vind aan deze
          feedback is dat mijn archetype goed naar voren is gekomen in mijn
          ontwerp en dat het zelfs mogelijk was dat ik er twee verschillende in
          had verwerkt (ook het archetype creator), zonder dat ik dit zelf door
          had.
        </p>
      </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */


.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
   margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  
  .img-extra {
    max-width: 200px;
    margin-right: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 30%;
    margin-bottom: 50px;
  }
}
.reflection {
    margin-right: 20%;
    margin-left: 20%;
    background-color: black;
    margin-top: 20px;
    margin-bottom: 50px;
    p {
      color: white;
      font-family: "Proxima Nova Light";
      text-align: left;
      font-size: 14px;
      text-align: justify;
      padding: 0px 150px 40px 150px;
    }
    h2 {
      color: white;
      padding-top: 25px;
      font-family: Futura;
      font-weight: 600;
    }
  }

#section2 {
  margin-top: 50px;
  max-width: 450px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
  margin-top: 100px !important;
}

div.gallery img {
  width: auto!important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#gallery2{
    div.gallery img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}
}
@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 50%;
    .img-page {
      max-width: 350px !important;
    }
    
    .img-extra {
      margin-left: 25% !important;
      margin-top: 50px;
      max-width: 200px !important;
    }
  }
  .reflection {
      margin-top: 120px !important;
    }
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 300px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 300px !important;
    }
    margin-left: 50%;
    .img-extra {
      margin-left: 25% !important;
      max-width: 200px !important;
      margin-top: 50px;
    }
    .reflection {
      margin-top: 450px !important;
      max-width: 350px !important;
    }
  }

  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces {
    margin-top: 300px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
