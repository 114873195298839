<template>
  <div>
    <div class = "welcome">
    <p>Welkom op suzanneraaijmakers.nl!</p>
    </div>


  </div>
</template>


<style lang="scss">
.welcome{
  text-align: center;
  margin-top: 300px;
  p{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: #000000;
  }
}

</style>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>
