<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/fotografie">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Fotografie</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/typografie">
      <p class="button-p">Typografie</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/pages/huisstijl.png">
        <img class="img-page" src="/img/minor/pages/huisstijl.png"
      /></a>
      <p class="title-page">HUISSTIJL</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Aan het begin van de minor heb ik een eigen huisstijl bedacht voor
        mezelf als grafisch ontwerper. Met deze huisstijl kan ik laten zien wie
        ik ben en wat mijn stijl is. Mijn eigen huisstijl bestaat uit een aantal
        elementen zoals een logo, kleurenpalet en lettertypes. Opmerking: in de
        afbeeldingen onderaan de pagina zijn bladzijdes uit mijn
        huisstijlhandboek te zien. Hierbij is er sprake van opvultekst,
        aangezien ik geen tijd heb gehad om deze te vullen met echte tekst.
        <br /><br />
        <b>Logo</b><br />
        In de afbeelding hiernaast en in afbeelding 1 onder aan de pagina is
        mijn logo te zien. Ik heb gekozen voor zowel een woord- als beeldmerk
        (waarbij het beeldmerk bestaat uit een afkorting van het woordmerk). Het
        woordmerk kan gebruikt worden op plaatsen waar veel plek is voor het
        logo, zoals bijvoorbeeld in het navigatiemenu van dit portfolio. Het
        beeldmerk kan gebruikt worden als icoon (zoals het icoon in het tabblad
        van mijn portfolio), of wanneer er weinig ruimte is voor het volledige
        logo. In afbeelding 2 is een deel uit mijn huisstijlhandboek te zien
        waarin ik het logo heb verwerkt en beschreven. Hierbij heb ik ook
        aangegeven in welke kleur deze gebruikt kan worden (zwart of wit), op
        basis van de achtergrond. <br /><br />
      </div>
      <div class="text" id="section2">
        <b>Kleur</b><br />

        In afbeelding 3 is het kleurenpalet van mijn huisstijl te zien. Ik heb
        gekozen voor herfsttinten, welke mooi te gebruiken zijn in combinatie
        met zwart en wit. Hierbij blijft zwart en wit de basis en kan er één
        kleur uit mijn kleurenpalet worden toegevoegd voor een mooie opmaak.
        (In mijn portfolio website heb ik enkel alleen gebruik gemaakt van
          zwart en wit omdat ik graag wilde dat de afbeeldingen met daarin mijn
          proces en eindwerken goed naar voren kwamen).
        
        <br /><br />
        <b>Lettertypes</b><br />
        Als laatste heb ik mijn lettertypes bepaald, zie afbeelding 4. Ik heb
        gekozen voor een dik en aanwezig font voor de titels en een wat lichter
        font voor de bodytekst (waarbij een bold-font gebruikt kan worden voor
        kopjes). De gekozen lettertypes kan ik gebruiken wanneer ik iets moet
        documenteren of binnen een eventueel factuur voor een klant
        (toekomstperspectief).
        <br /><br />
        <b>Logo op tas</b><br />
        In de afbeelding hiernaast is mijn ontworpen logo als afdruk te zien op
        de achterkant van het stoffen tasje van de minor Graphic Design. Tijdens
        de workshop plotten hebben we onze eigen vormen mogen drukken op een
        stoffen ondergrond. Ik heb hierbij gekozen om gebruik te maken van mijn eigen ontworpen
        logo, om deze als een soort ‘merchandise’ te kunnen presenteren en om
        het tasje helemaal eigen te maken. Ik vind dat de druk goed is gelukt en
        ik ben blij met het ontwerp van mijn logo.

        <br /><br />
      </div>

      <a target="_blank" href="/img/minor/pages/huisstijl5.jpeg">
        <img class="img-extra" src="/img/minor/pages/huisstijl5.jpeg" />
      </a>
    </div>
                <p class = "proces">PROCES</p>
    <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/huisstijl0.png">
          <img
            src="/img/minor/pages/huisstijl0.png"
            alt=""
            
          />
        </a>
        <div class="desc">1. Woordmerk</div>
      </div>
    </div>
     <div class="image-gallery">
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/huisstijl2.png">
          <img
            src="/img/minor/pages/huisstijl2.png"
            alt=""
         
          />
        </a>
        <div class="desc">2. Huisstijlhandboek</div>

        <a target="_blank" href="/img/minor/pages/huisstijl3.png">
          <img
            src="/img/minor/pages/huisstijl3.png"
            alt="Forest"
          
          />
        </a>
        <div class="desc">3. Huisstijlhandboek</div>
      </div>
      <div class="gallery">
        <a target="_blank" href="/img/minor/pages/huisstijl4.png">
          <img
            src="/img/minor/pages/huisstijl4.png"
            alt="Forest"
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Huisstijlhandboek</div>
      </div>
    </div>
    <div class="reflection">
      <h2>REFLECTIE</h2>
      <p>
        Ik heb deze huisstijl bedacht tijdens de eerste weken van de minor
        Graphic Design. Bij het toevoegen van dit onderdeel aan mijn portfolio
        ben ik erachter gekomen dat ik met de kennis en vaardigheden die ik nu
        heb (eind van de minor), de huisstijl en de presentatie hiervan zeker
        nog wil aanpassen. Ik heb veel technieken geleerd binnen de minor,
        waaronder in Adobe Indesign. Met deze technieken kan ik mijn huisstijl
        op een betere manier presenteren dan dat ik nu heb gedaan waardoor het er
        professioneler uit komt te zien.
      </p>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 350px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  //   .reflection {
  //     position: absolute !important;
  //     left: 20% !important;
  //     max-width: 500px !important;
  //     margin-top: 480px !important;
  //   }
  .img-extra {
    max-width: 300px;
    margin-right: 0px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 40%;
    margin-bottom: 100px;
  }
}
.reflection {
  margin-right: 20%;
  margin-left: 20%;
  background-color: black;
  margin-top: 50px;
  margin-bottom: 50px;
  p {
    color: white;
    font-family: "Proxima Nova Light";
    text-align: left;
    font-size: 14px;
    text-align: justify;
    padding: 0px 150px 40px 150px;
  }
  h2 {
    color: white;
    padding-top: 25px;
    font-family: Futura;
    font-weight: 600;
  }
}

#section2 {
  margin-top: 30px;
  max-width: 450px;
}


.image-gallery {
  display: flex !important;
  margin-bottom: 60px;
  justify-content: center !important;
  flex-wrap: wrap;
  margin-right: 25% !important;
  margin-left: 25% !important;
  flex-direction: row;
}

#gallery2 {
  margin-top: 0px !important;
}

div.gallery {
  margin: 5px 15px 5px 5px;
  //border: 1px solid #ccc;
  //max-width: 100% !important;
}


div.gallery img {
  width: 500px !important;
  height: auto !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

@media only screen and (max-width: 1500px){
  .section {
  margin-left: 50%;
  .reflection{
  margin-top: 280px !important;
   max-width: 400px !important;
  }
  .img-extra {
    
    margin-left: 30% !important;
}}
#section2 {
  max-width:400px !important;
  margin-top: 50px;
}
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 350px;
    }
    margin-left: 50%;
    .img-extra {
    
    margin-left: 25% !important;
}
  }
  
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces{
    margin-top: 250px;
  }

}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
