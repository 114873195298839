<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/graphic-hero">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Graphic Hero Flyer</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/fotografie">
      <p class="button-p">Fotografie</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/pages/foodybag-4.png">
        <img class="img-page" src="/img/minor/pages/foodybag-4.png"
      /></a>
      <p class="title-page">TAS VAN VERSCHIL</p>
      <p class="description">Conceptueel</p>
      <div class="text">
        Ik heb voor mijn conceptuele project de Foodybag bedacht, ontworpen, en
        gerealiseerd.
        <br /><br /><i
          >“De Foodybag is een fancy en luxe fashion item die mensen aangeboden
          krijgen in de horeca zodat ze hun restjes eten van de genuttigde
          maaltijd mee naar huis kunnen nemen. Door het chique uiterlijk van de
          Foodybag hoeft niemand zich te schamen om hiermee rond te lopen. Door
          gebruik te maken van de Foodybag lever je een bijdrage tegen
          voedselverspilling. Het is een tas van verschil.”
        </i>

        <br /><br />
      </div>
      <div class="text" id="section2">
        Ik heb onderzoek gedaan naar wereldproblemen, waarbij ik voedselverspilling
        erg interessant vond. Binnen het thema voedselverspilling heb
        ik gekeken naar de verscillende sectoren en wat er nu al tegen wordt
        gedaan. Hierbij ben ik uitgekomen op de sector horeca waar de doggybag
        langzaam aan het opkomen is. Ik heb ervoor gekozen om de doggybag een
        nieuw imago te geven zodat mensen zich niet meer schamen om hiermee rond
        te lopen.
        <br /><br />
        In de ontwerpfase heb ik de Foodybag ontworpen. Hierbij heb ik
        doormiddel van inspiratie van luxe- en designermerken een eigen tas
        bedacht (afbeelding 1, onderaan de pagina). Ik heb het ontwerp van de
        tas simpel gehouden zodat het een professionele en luxe uitstraling
        krijgt. In de afbeelding 2 is het ontwerp van de Foodybag te zien.
        <br /><br />
        In de realisatiefase heb ik mijn Foodybag gerealiseerd en gepresenteerd
        in de vorm van drie posters (zowel individueel als samen). Voor de
        fotoshoot heb ik een moodboard gemaakt met daarop inspiratie voor het
        presenteren van de tas (met model). Dit moodboard is te zien in
        afbeelding 3. De defintieve posters van mijn ‘tas
        van verschil’ zijn te zien in afbeelding 4 t/m 6. De 3 posters samen
        vertellen het hele verhaal van de Foodybag en wat het doel hiervan is.
        Deze posters samen zijn te zien in de afbeelding hierboven.

        <br /><br />
      </div>
      <div class="reflection">
        <h2>REFLECTIE</h2>
        <p>
          Ik vond mijn conceptuele project leuk, vernieuwend en ook zeker
          uitdagend. Het onderzoek doen naar wereldproblemen is vrij vlot
          verlopen, maar vanuit mijn gekozen onderwerp (doggybag) een bepaalde
          invalshoek vinden heeft een tijdje geduurd. Toen ik uiteindelijk mijn
          invalshoek had, is de ontwerpfase heel snel gegaan. Ik had precies in
          mijn hoofd hoe ik wilde dat de Foodybag eruit kwam te zien en dat heb
          ook zo ontworpen. De realisatiefase was erg leuk, aangezien ik een
          fotoshoot heb gedaan met mijn zelfontworpen tasje. Het verwerken van
          de foto’s in de posters om mijn concept te presenteren was uitdagend.
          Ik heb hierbij gebruik gemaakt van Adobe Photoshop, wat niet mijn
          sterkste Adobe programma is. Door hier gebruik van te maken heb ik
          heel veel geleerd, wat ik in volgende projecten ook kan toepassen.
          <br /><br />
          Kortom, dit conceptuele project heeft ervoor gezorgd dat ik in het
          bedenken van concepten en het uitwerken als grafisch designer zeker
          stappen heb gemaakt en veel heb geleerd.
        </p>
      </div>
    </div>
    <p class="proces">PROCES</p>
    <div class="image-gallery" id="gallery1">
      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/moodboard_designer-paperbags-01.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/moodboard_designer-paperbags-01.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">1. Inspiratie</div>
      </div>
      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/Shopping_Bag_Paper_Mockup.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/Shopping_Bag_Paper_Mockup.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">3. Ontwerp Foodybag</div>
      </div>

      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/moodboard_poster-01.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/moodboard_poster-01.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">2. Moodboard</div>
      </div>
    </div>
    <div class="image-gallery" id="gallery2">
      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/IMG_0744.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/IMG_0744.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">4. Poster 1</div>
      </div>
      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/IMG_0781-v1.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/IMG_0781-v1.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">5. Poster 2</div>
      </div>
      <div class="gallery">
        <a
          target="_blank"
          href="/img/minor/gecomprimeerd/foodybag/IMG_0797-v1.png"
        >
          <img
            src="/img/minor/gecomprimeerd/foodybag/IMG_0797-v1.png"
            alt=""
            width="600"
            height="400"
          />
        </a>
        <div class="desc">6. Poster 3</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss">
//zie analyse.vue style :)

.button {
  display: flex;
  justify-content: right;
  flex-direction: columns;
  align-content: center;
  align-items: center;
  z-index: 2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 1;
  .button-img {
    width: 16px;
    height: 16px;
    font-family: "Proxima Nova Light";
  }
  .button-p {
    font-family: "Proxima Nova Light";
    font-size: 12px;
    color: black;
    max-width: 80px;
  }
  .button-p:hover {
    text-decoration: underline;
  }
}
.button:focus {
  outline: none !important;
}

#back {
  position: fixed;
  text-decoration: none !important;
  left: 80px;
  top: 80px;
  .button-p {
    padding-left: 10px;
  }
  .button-img {
    padding-left: 0;
  }
}

#next {
  position: fixed;
  float: right;
  top: 80px;
  right: 80px;
  text-decoration: none !important;
  .button-p {
    padding-left: 0;
  }
  .button-img {
    padding-left: 10px;
  }
}

.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 0;
  margin-top: 150px;
  color: black;
  .img-page {
    position: absolute;
    max-width: 450px;
    left: 20%;
    margin: 0;
    //box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }
  .description {
    font-family: "Proxima Nova Light";
    text-align: left;
    margin: 0;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  h6 {
    font-family: "Proxima Nova Semibold";
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .important {
    display: flex;
    color: #b57d78;
    font-family: "Proxima Nova Semibold";
    text-align: center;
    justify-content: center;
  }
  .text {
    font-family: "Proxima Nova Light";
    text-align: left;
    font-size: 14px;
    text-align: justify;
  }
  .reflection {
    background-color: black;
    margin-top: 80px;
    p {
      color: white;
      font-family: "Proxima Nova Light";
      text-align: left;
      font-size: 14px;
      text-align: justify;
      padding: 0px 40px 40px 40px;
    }
    h2 {
      color: white;
      padding-top: 25px;
      font-family: Futura;
      font-weight: 600;
    }
  }
}
.proces {
  margin-top: 100px;
  font-family: "Futura Bold";
  color: black;
  font-size: 20px;
}
#section2 {
  position: absolute;
  left: 20%;
  max-width: 450px;
  margin-top: 80px;
}

.image-gallery {
  display: flex !important;
  margin-bottom: 60px;
  justify-content: center !important;
  flex-wrap: wrap;
  margin-right: 18% !important;
  margin-left: 18% !important;
  flex-direction: row;
}

#gallery2 {
  margin-top: 0px !important;
}

div.gallery {
  margin: 5px 15px 5px 5px;
  //border: 1px solid #ccc;
  max-width: 240px;
}

div.desc {
  padding: 5px;
  text-align: center;
  font-size: 12px;
}

div.gallery img {
  max-width: 250px !important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

@media only screen and (max-width: 1500px) {
  .section {
    margin-left: 55%;
    .reflection {
      margin-top: 50px;
    }
  }
  #section2 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 400px;
    }
    margin-left: 55%;
    .reflection {
      margin-top: 50px;
    }
  }
  #section2 {
    max-width: 400px;
    margin-top: 50px;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
