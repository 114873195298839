<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/bierpull">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Bierpull</p>
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/salve mundi.png">
        <img class="img-page" src="/img/minor/content-img/salve mundi.png"
      /></a>
      <p class="title-page">SALVE MUNDI</p>
      <p class="description">Eigen werk</p>
      <div class="text">
        Het ontwerp in de afbeelding hiernaast heb ik gemaakt als ontwerp voor op een hoodie welke
        alle actieve leden in mijn studievereniging Salve Mundi
        (studievereniging HBO-ICT) krijgen aan het einde van een bestuursjaar.
        Dit ontwerp heb ik niet gemaakt binnen de minor Graphic design, maar ik
        heb verschillende vaardigheden binnen Adobe Illustrator, welke ik heb
        geleerd binnen de minor, wel kunnen toepassen. Zo heb ik in dit ontwerp
        gebruik gemaakt van een verschillende lagen, een techniek die ik voor
        deze minor nog niet eerder heb toegepast.
        <br /><br />
        Dit ontwerp is een voorbeeld van de kennis en vaardigheden die ik heb
        opgedaan tijdens de minor Graphic Design en vervolgens in andere
        projecten heb toegepast.
      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 400px;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .img-extra {
    max-width: 460px;
    margin-right: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 9%;
    margin-bottom: 50px;
  }
}
.reflection {
  margin-right: 20%;
  margin-left: 20%;
  background-color: black;
  margin-top: 20px;
  margin-bottom: 50px;
  p {
    color: white;
    font-family: "Proxima Nova Light";
    text-align: left;
    font-size: 14px;
    text-align: justify;
    padding: 0px 150px 40px 150px;
  }
  h2 {
    color: white;
    padding-top: 25px;
    font-family: Futura;
    font-weight: 600;
  }
}

#section2 {
  margin-top: 50px;
  max-width: 500px;
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
  margin-top: 100px !important;
}

div.gallery img {
  width: auto !important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#gallery2 {
  div.gallery img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    object-position: 50% 50%;
    margin-right: 20px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .img-page {
    max-width: 350px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
