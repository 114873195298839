<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/fotografie-2">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Fotografie metaforen</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/salvemundi">
      <p class="button-p">Salve Mundi</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/bierpull.png">
        <img class="img-page" src="/img/minor/content-img/bierpull.png"
      /></a>
      <p class="title-page">BIERPULL</p>
      <p class="description">Eigen werk</p>
      <div class="text">
        In de afbeelding hiernaast is een bierpull ontwerp te zien welke ik heb
        gemaakt binnen de mediacommissie van mijn studievereniging Salve Mundi
        (studievereniging HBO-ICT). Dit ontwerp heb ik niet gemaakt binnen de
        minor Graphic design, maar ik heb verschillende vaardigheden binnen
        Adobe Illustrator, welke ik heb geleerd binnen de minor, wel kunnen
        toepassen. Het bierglas in het midden van de mock-up heb ik zelf
        getekend, wat goed te doen was door de verschillende technieken die ik
        heb geleerd bij de workshops.
        <br /><br />
        Kortom, dit ontwerp is een voorbeeld van de kennis en vaardigheden die
        ik heb opgedaan tijdens de minor Graphic Design en vervolgens in andere
        projecten heb toegepast.
      </div>
    </div>

    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 380px;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 400px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

 
}

.image-gallery {
  margin-left: 20% !important;
  margin-right: 20% !important;
  margin-top: 100px !important;
}

div.gallery img {
  width: auto !important;
  height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 20px !important;
}

#gallery2 {
  div.gallery img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    object-position: 50% 50%;
    margin-right: 20px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .img-page{
    max-width: 350px !important;
  }
}
</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
