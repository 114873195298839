<template>
  <div>
    <Navigation />
    <router-link class="button" id="back" to="/minor-content/huisstijl">
      <img class="button-img" src="/img/icons/left-arrow.png" />
      <p class="button-p">Huisstijl</p>
    </router-link>
    <router-link class="button" id="next" to="/minor-content/portretbewerking">
      <p class="button-p">Portretbewerking</p>
      <img class="button-img" src="/img/icons/right-arrow.png" />
    </router-link>

    <div class="section">
      <a target="_blank" href="/img/minor/content-img/jeff-koons.jpeg">
        <img class="img-page" src="/img/minor/content-img/jeff-koons.jpeg"
      /></a>
      <p class="title-page">TYPOGRAFIE</p>
      <p class="description">Workshop minor</p>
      <div class="text">
        Binnen de workshop typografie heb ik de opdracht gekregen om een boek te
        ontwerpen voor een bepaalde kunstenaar. Ik heb gekozen voor de
        kunstenaar Jeff Koons, aangezien dit de enige kunstenaar uit de lijst
        was die ik al kende. Jeff Koons staat bekend om zijn honden ballon
        kunstwerken, zie de afbeelding hiernaast. In zijn werk komen de thema’s
        banaliteit en verleiding terug en zijn werk wordt veelal benoemd als
        postmodern of kitsch.
        <br /><br />
        Zelf vind ik het werk van Jeff Koons heel gaaf. Dit komt vooral door de
        vormen, kleuren en verschillende groottes van zijn werken. De ‘Balloon
        Dog’ is zelf mijn favoriet, vandaar dat ik deze ook zeker heb laten
        terugkomen in het boek dat ik voor hem heb ontworpen.
        <br /><br />
        Het boek is te zien in de afbeeldingen hieronder. Ik heb ervoor gekozen
        om in de stijl van het boek veel ruimte over te laten voor de
        afbeeldingen met daarin zijn werk. Afbeeldingen zeggen meer dan woorden
        en daarom komt er maar weinig tekst voor in het ontwerp. Verder heb ik
        bij alle afbeeldingen een bron neergezet, zodat mensen die dit boek
        doorbladeren ook kunnen zien waar het kunstwerk vandaan komt.
        <br /><br />
        Als typografie heb ik gekozen voor een duidelijk en rond lettertype voor
        de tekststukken en een wat groter lettertype voor de titels. Beide
        lettertypes zijn vrij rond, hier heb ik voor gekozen om dat het werk van
        Jeff (wat ik in mijn boek laat terugkomen) vooral bestaat uit ronde
        vormen. Verder heb ik ervoor gekozen om de tekst in het boek gewoon
        zwart te laten, zodat deze het minste opvalt ten opzichte van de
        kleurrijke afbeeldingen.
      </div>
    </div>
    <div class="image-gallery" id = "img1">
        <a target="_blank" href="/img/minor/pages/Boek_Jeff-Koons.png">
          <img
            src="/img/minor/pages/Boek_Jeff-Koons.png"
          />
        </a>
    </div>
        <div class="image-gallery">
        <a target="_blank" href="/img/minor/pages/Boek_Jeff-Koons2.png">
          <img
            src="/img/minor/pages/Boek_Jeff-Koons2.png"
          />
        </a>
    </div>
        <div class="image-gallery">
        <a target="_blank" href="/img/minor/pages/Boek_Jeff-Koons3.png">
          <img
            src="/img/minor/pages/Boek_Jeff-Koons3.png"
          />
        </a>
      </div>

        <div class="image-gallery">
        <a target="_blank" href="/img/minor/pages/Boek_Jeff-Koons4.png">
          <img
            src="/img/minor/pages/Boek_Jeff-Koons4.png"
          />
        </a>
    </div>

    <div class="reflection">
      <h2>REFLECTIE</h2>
      <p>
        Ik vond deze typografie workshop erg leuk en ik vond het ook erg leuk om
        een opdracht te doen in Adobe Indesign. Ik heb van Marlinde de feedback
        gekregen dat het toevoegen van bronnen een goede toevoeging is geweest
        aan het boek. Verder stonden er nog wat uitlijningsfoutjes in mijn
        eerste versie, welke ik heb verbeterd in de versie die in dit portfolio
        te zien is. De enige feedback waar ik de volgende keer nog extra goed op
        moet letten is het onderbouwen van gemaakte designkeuzes. In mijn
        ontwerp heb ik gekozen voor het gebruik van een herhaling van ‘drie
        zwarte bolletjes’ onder de titel van de pagina. Waarom heb ik voor deze
        bolletjes gekozen? Ik vond dit mooi staan in het ontwerp, maar verder
        heb ik geen onderbouwing voor deze keuze. Dit is een feedbackpuntje wat
        ik mee kan nemen binnen de presentatie ontwerpen, zodat ik mijn gemaakte
        keuzes in het vervolg kan onderbouwen.
      </p>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
/* styling staat bij "foodybag" */
.section {
  margin-left: 50%;
  margin-right: 20%;
  margin-bottom: 100px;
  margin-top: 150px;
  color: black;
  .img-page {
    max-width: 470px;
  }
  .title-page {
    font-family: "Futura";
    font-size: 20px;
    //padding-top: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }

  .img-extra {
    max-width: 460px;
    margin-right: 0px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 9%;
    margin-bottom: 50px;
  }
}
.reflection {
  margin-right: 20%;
  margin-left: 20%;
  background-color: black;
  margin-top: 130px;
  margin-bottom: 120px;
  p {
    color: white;
    font-family: "Proxima Nova Light";
    text-align: left;
    font-size: 14px;
    text-align: justify;
    padding: 0px 150px 40px 150px;
  }
  h2 {
    color: white;
    padding-top: 25px;
    font-family: Futura;
    font-weight: 600;
  }
}

#section2 {
  margin-top: 50px;
  max-width: 500px;
}

.image-gallery {
  display: flex !important;
  justify-content: center !important;
  flex-wrap: wrap;
  margin-right: 25% !important;
  margin-left: 25% !important;
  flex-direction: row;
  img {
    width: 700px;
  }
  margin-bottom: 10px !important;
}
#img1 img{
    width: 350px !important;
  }





.button{
  .button-p {
    max-width: 200px !important ;
  }
}
@media only screen and (max-width: 1400px) {
  .section {
    .img-page {
      max-width: 350px;
    }
    margin-left: 50%;
    .img-extra {
    
    margin-left: 25% !important;
}
  }
  
  #section2 {
    max-width: 350px !important;
    margin-top: 50px;
  }
  .proces{
    margin-top: 250px;
  }

}

</style>

<script>
import Navigation from "@/components/navigation.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  components: { Navigation, Footer },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
